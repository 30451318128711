import * as React from 'react'
import { OfferDetailsAgreements } from '@modules/offers/common/details/personal-data/offer-details-agreements'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'

export const EndlessHolidayAgreements = (): React.ReactNode => {
  const { product_packages } = useAppSelector(selectAppData)

  return <OfferDetailsAgreements rulesUrl={product_packages[0]?.urls?.rules || ''} />
}
