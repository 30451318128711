import { EndlessHolidayFormInputProduct } from '@modules/offers/endless-holiday/details/index'
import { useEndlessHolidayAddedProducts } from './use-endless-holiday-products'
import { asDecimal, sumDecimalArray } from '@helpers/utils'

interface Response {
  totalPrice: string
}

export const useEndlessHolidayTotalPrice = (products: EndlessHolidayFormInputProduct[]): Response => {
  const { subscriptionProducts, gastroCardProducts } = useEndlessHolidayAddedProducts(products)

  const totalPrice = sumDecimalArray(
    subscriptionProducts.map(products => {
      const gastroProduct = gastroCardProducts.find(
        gastroProduct => gastroProduct.related_product === products.product.id,
      )

      const subscriptionsPrice = asDecimal(products.amount).mul(asDecimal(products.product.price_brutto))

      const optionsPrice = sumDecimalArray(products.options.map(option => asDecimal(option.price))).mul(products.amount)

      const depositPrice = asDecimal(products.amount).mul(products.product.deposit_amount)

      const gastroPrice = gastroProduct
        ? asDecimal(products.amount).mul(asDecimal(gastroProduct.product.price_brutto))
        : asDecimal(0)

      return subscriptionsPrice.add(gastroPrice).add(depositPrice).add(optionsPrice)
    }),
  ).toString()

  return {
    totalPrice,
  }
}
