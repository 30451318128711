import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { OfferDetailsPersonalDataManualBoxPoint } from '@modules/offers/common/details/personal-data/offer-details-personal-data-manual-box-point'

export const EndlessHolidayManualBox = (): JSX.Element => (
  <OfferDetailsRow
    title={
      <span className="text-primary">
        <strong className="d-block">Co musisz </strong>zrobić?
      </span>
    }
  >
    <div className="subscription-with-benefit__personal-data__information-box gastro-card__personal-data__information-box">
      <OfferDetailsPersonalDataManualBoxPoint number={1}>
        <strong className="text-primary">Wybierz</strong> wariant oferty <br />
        przygotowanej specjalnie <br />
        dla Ciebie.
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={2}>
        <strong className="text-primary">Zaloguj się </strong>
        lub uzupełnij dane,
        <br /> jeśli jesteś nowym klientem.
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={3}>
        <strong className="text-primary">Zaakceptuj</strong> regulaminy <br /> i złóż zamówienie.
      </OfferDetailsPersonalDataManualBoxPoint>
      <OfferDetailsPersonalDataManualBoxPoint number={4}>
        <strong className="text-primary">Opłać </strong>
        zamówienie, <br />a otrzymasz vouchery.
      </OfferDetailsPersonalDataManualBoxPoint>
    </div>
  </OfferDetailsRow>
)
