import * as React from 'react'
import { OrderNowButton } from '@components/controls/buttons/order-now-button'
import { useScrollToOffer } from '@hooks/use-scroll-to-offer'

interface Props {
  onActionClick: () => void
  description: React.ReactNode
  title: React.ReactNode
  image: string
  alt: string
  scrollAnchor: string
}

export const GastroCardBox = ({
  onActionClick,
  description,
  title,
  image,
  alt,
  scrollAnchor,
}: Props): React.ReactNode => {
  const { ref } = useScrollToOffer(scrollAnchor)
  return (
    <div className="col-lg-6 col-12" ref={ref}>
      <div className="card">
        <img src={image} className="card-img-top cursor-pointer" alt={alt} onClick={onActionClick} />
        <div className="card-body p-5">
          <h5 className="text-secondary offers__header fw-bold font-header">{title}</h5>
          <p className="card-text font-xl mb-5">{description}</p>
          <OrderNowButton text="Zobacz więcej" onClick={onActionClick} />
        </div>
      </div>
    </div>
  )
}
