import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import { formatPriceShort } from '@helpers/price-helper'
import { useDevicesSize } from '@hooks/use-devices-size'

export const SubscriptionsOffer = (): React.ReactNode => {
  const { isMobile } = useDevicesSize()
  const { configuration } = useAppSelector(selectAppData)

  const handleShowDetails = () => {
    window.location.href = configuration.single_code_offer_url
  }

  return (
    <div className="container-lg">
      <OfferSection
        backgroundVideo={isMobile ? undefined : require('@assets/images/offers/subscriptions/background-video.mp4')}
        title={
          <span className="font-header">
            Pojedyncze vouchery <br /> pobytowe
          </span>
        }
        description={
          <>
            <strong>Wybierz dowolną ilość voucherów, bez konieczności zakupu całego pakietu.</strong>
            <span className="d-block">
              Skorzystaj z oferty i odwiedź nas <span className="text-nowrap">w dogodnym</span> dla siebie terminie.{' '}
              <div className="mt-1">
                Możliwość rezerwacji również <span className="text-nowrap">w lokalach specjalnych!</span>
              </div>
            </span>
          </>
        }
        onOrderClick={handleShowDetails}
        onImageClick={handleShowDetails}
        orderText="Zobacz więcej"
        offerKind="subscription"
        price={{
          pre: (
            <>
              <div>Od</div>
              <strong className="font-header-lg">{formatPriceShort(899)}</strong>
            </>
          ),
          after: (
            <div className="text-end">
              za 6-dniowy <br /> pobyt
            </div>
          ),
          shapePosition: 'bottom',
          className: 'font-sm pt-3',
        }}
      />
    </div>
  )
}
