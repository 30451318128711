export enum NavigationPath {
  Homepage = '/',
  SubscriptionWithBenefits = '/pakiet-korzysci/',
  GastroCardBoost = '/doladowanie-smaku/',
  GastroCard = '/podwojna-radosc-2026-2027/',
  EndlessHoliday = '/wakacje-w-nieskonczonosc/',
  GastroCardDynamic = '/podwojna-radosc-2025-plus/',
  CodeConversion = '/ulepszanie-kodu/',
  SubscriptionContract = '/subskrypcja/',
  Rules = '/regulamin/',
  GoHoliday = '/go-holiday/',
  NotFound = '/nie-znaleziono/',
}
