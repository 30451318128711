import * as React from 'react'
import { EndlessHolidayProduct } from '@modules/offers/endless-holiday/models'
import { EndlessHolidayProductVariantGastroCards } from '@modules/offers/endless-holiday/details/products-selection/product-variant/options/endless-holiday-product-variant-gastro-cards'
import { EndlessHolidayProductVariantStayOptions } from '@modules/offers/endless-holiday/details/products-selection/product-variant/options/endless-holiday-product-variant-stay-options'

interface Props {
  gastroProducts: EndlessHolidayProduct[]
  subscriptionProduct: EndlessHolidayProduct
  children: React.ReactNode
  priceSurplusWithoutGastro: string
}

export const EndlessHolidayProductVariantOptions = ({
  gastroProducts,
  subscriptionProduct,
  priceSurplusWithoutGastro,
  children,
}: Props): React.ReactNode => (
  <div className="text-darker-gray endless-holiday__voucher-selection ms-auto col-lg-11 col-12">
    {children}
    <EndlessHolidayProductVariantGastroCards
      gastroProducts={gastroProducts}
      subscriptionProduct={subscriptionProduct}
      priceSurplusWithoutGastro={priceSurplusWithoutGastro}
    />
    <EndlessHolidayProductVariantStayOptions subscriptionProduct={subscriptionProduct} />
  </div>
)
