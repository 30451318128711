import * as React from 'react'
import { BaseGastroCardAmount } from '@modules/offers/common/base-gastro-card-amount/base-gastro-card-amount'
import { formatPriceShort } from '@helpers/price-helper'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/app-slice'
import { hasActiveSubscriptionContract } from '@modules/offers/gastro-card-boost/utils'
import { GastroCardSubscriptionBonusesInformation } from '@modules/offers/gastro-card/normal/details/gastro-card-subscription-bonuses-information'
import { BaseGastroCardDynamicAmountBox } from '@modules/offers/gastro-card/dynamic/details/gastro-card-dynamic-amount-box'
import { useGastroDynamicMultipliers } from '@modules/offers/gastro-card/dynamic/details/use-gastro-dynamic-multipliers'

interface Props {
  onScrollToDiscountsTable: () => void
}

export const GastroCardDynamicAmount = ({ onScrollToDiscountsTable }: Props): React.ReactNode => {
  const client = useSelector(selectClientDetails)

  const hasActiveSubscription = hasActiveSubscriptionContract(client)
  const { currentOffer } = useGastroDynamicMultipliers()

  return (
    <BaseGastroCardAmount
      amountBox={<BaseGastroCardDynamicAmountBox onScrollToDiscountsTable={onScrollToDiscountsTable} />}
      regularFactor={Number(currentOffer?.multiplier_value ?? 0)}
      subscriptionFactor={Number(currentOffer?.multiplier_value_for_subscriber ?? 0)}
      kindClassName="is-gastro-card-dynamic"
      centerCardTitle="Dodajemy:"
      sectionTitle={
        <span className="text-secondary">
          <strong>Wpisz</strong> jaką kwotę <br /> mamy powiększyć
        </span>
      }
      subscriptionBonuses={({ subscriptionPercentageBonus }) => (
        <GastroCardSubscriptionBonusesInformation subscriptionPercentageBonus={subscriptionPercentageBonus} />
      )}
    >
      {({ amountToSpent, amountValue }) => (
        <div className="text-center text-md-start d-flex flex-column h-100 justify-content-between ps-5 ps-lg-0 mb-5 mb-lg-0">
          <div>
            <span className="text-primary font-xxxl d-block mb-2 fw-semi-bold">Twój voucher</span>
            <span className="text-white lh-sm fw-semi-bold d-block mt-3" style={{ fontSize: 32 }}>
              Podwójna Radość <br /> 2025 Plus
            </span>
          </div>

          <section className="mt-5 mb-3">
            <span className="text-primary fw-semi-bold font-xxxl">Wartość vouchera:</span>
            <span className="font-header fw-semi-bold d-block text-white" style={{ fontSize: 40 }}>
              {formatPriceShort(amountToSpent)}
            </span>
            <IconWithText
              text={
                <span className="font-sm">
                  {hasActiveSubscription ? (
                    <span>
                      <strong className="price-crossed">{formatPriceShort(amountValue.regular)}</strong> dla regularnych
                      klientów
                    </span>
                  ) : (
                    <>
                      <strong>{formatPriceShort(amountValue.subscription)}</strong> dla subskrybentów
                    </>
                  )}
                </span>
              }
              iconClassName="uil-info-circle me-2"
              className="text-white"
            />
          </section>
        </div>
      )}
    </BaseGastroCardAmount>
  )
}
