import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { EndlessHolidayFormOption } from '@modules/offers/endless-holiday/details'
import { asDecimal } from '@helpers/utils'

interface Props {
  option: EndlessHolidayFormOption
  amount: number
}

export const EndlessHolidaySummaryOptionRow = ({ option, amount }: Props): React.ReactNode => {
  const OptionName = { single_client_date: 'Zmiana daty' }[option.kind] ?? 'Opcja dodatkowa'
  const price = asDecimal(option.price).mul(amount).toString()

  return (
    <div key={option.kind} className="text-muted d-flex justify-content-between ms-3 font-500">
      <div>
        {OptionName} {amount > 1 ? `${amount}x` : ''}
      </div>
      <div>+ {formatPriceShort(price)}</div>
    </div>
  )
}
