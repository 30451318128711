import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import clsx from 'clsx'
import { EndlessHolidayFormInputProduct } from '@modules/offers/endless-holiday/details'
import { asDecimal } from '@helpers/utils'

interface Props {
  gastroCards: EndlessHolidayFormInputProduct[]
  year: string
}

export const EndlessHolidaySummaryGastroCards = ({ gastroCards }: Props): React.ReactNode => {
  const getTotalPrice = (gastroCard: EndlessHolidayFormInputProduct) =>
    asDecimal(gastroCard.product.price_brutto).mul(gastroCard.amount).toString()

  if (!gastroCards.length) return null

  return (
    <div className="mt-3">
      {gastroCards.map((gastroCard, index) => (
        <div
          key={gastroCard.product.id}
          className={clsx('d-flex justify-content-between align-items-center text-darker-gray', { 'mt-1': index > 0 })}
        >
          <div className="fw-bold">
            {gastroCard.amount > 1 ? 'Vouchery gastronomiczne' : 'Voucher gastronomiczny'}{' '}
            {gastroCard.amount > 1 ? `x${gastroCard.amount}` : ''}
          </div>
          <div className="fw-bold">{formatPrice(getTotalPrice(gastroCard))}</div>
        </div>
      ))}
    </div>
  )
}
