import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'
import { formatPriceShort } from '@helpers/price-helper'
import { CircleCheck } from '@components/circle-check'

interface Props {
  benefits: string[]
}

export const GastroCardDynamicCostsInfo = ({ benefits }: Props): React.ReactNode => (
  <section className="font-sm my-4">
    <ExpandableProductDescription isCollapsable>
      <p className="fw-bold my-2">Kupuj wcześniej, zyskuj więcej!</p>

      <p className="mb-0">
        Skorzystaj ze specjalnej promocji na <strong>Voucher Podwójna Radość 2025 Plus</strong> do wykorzystania w
        Kawiarni, Bistro, Beach Barze i Słodkim Kąciku podczas pobytów w roku 2025 w Resortach Holiday Park & Resort.
      </p>

      <div className="my-4 row">
        {benefits.map(benefit => (
          <CircleCheck key={benefit} className="col-lg-6 mb-1 fw-semi-bold font-sm">
            {benefit}
          </CircleCheck>
        ))}
      </div>

      <p>
        Im wcześniej kupisz i więcej wpłacisz, tym większy bonus otrzymasz. Nie przegap okazji - działaj teraz. Dzięki
        aktywnej Subskrypcji możesz nabyć voucher o wartości powiększonej nawet o 100% wpłaconej kwoty. Wpłacony{' '}
        {formatPriceShort(1000)} to {formatPriceShort(2000)} do wykorzystania!
      </p>
      <p>Nie będąc Subskrybentem wartość vouchera może zostać zwiększona o 90% Twojej wpłaty.</p>
      <p>Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł</p>
      <p>
        O wartości uzyskanego bonusu decyduje moment i wartość wpłaty. Przykładowo:
        <ul className="list-unstyled">
          <li className="my-1">
            Nabycie vouchera we wrześniu 2024 za kwotę 1 000,00 zł zwiększa wartość Twojego vouchera aż do{' '}
            <span className="text-nowrap">1 700,00 zł (+70% bonusu)!</span>
          </li>
          <li>Zakup vouchera w październiku 2024 w kwocie 500,00 zł zwiększa jego wartość do 775,00 zł (+ 55%).</li>
        </ul>
        <strong>Posiadacze aktywnej subskrypcji otrzymują zawsze dodatkowe +10%.</strong>
      </p>
      <p>
        Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego
        część aż do momentu całkowitego wykorzystania.
      </p>
      <p>
        Voucher nie łączy się z kuponem Doładowanie Smaku i Strzał w dziesiątkę. Nie można go wykorzystać w rezerwacji,
        do której przypisany jest jakikolwiek voucher gastronomiczny. Po zużyciu środków z vouchera Podwójna Radość
        można podpiąć kolejny dowolny voucher gastronomiczny.
      </p>

      <p className="mb-0">Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
    </ExpandableProductDescription>
  </section>
)
