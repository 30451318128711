import * as React from 'react'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { IconWithText } from '@components/icon-with-text'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { FormProvider, useForm } from 'react-hook-form'
import { SubscriptionContractCostsInfo } from '@modules/offers/subscription-contract/details/subscription-contract-costs-info'
import { SubscriptionContractPersonalData } from '@modules/offers/subscription-contract/details/subscription-contract-personal-data'
import { SubscriptionContractPayment } from '@modules/offers/subscription-contract/details/subscription-contract-payment'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { SubscriptionContractOfferSelection } from '@modules/offers/subscription-contract/details/subscription-contract-offer-selection'
import { CLIENT_APP_URLS } from '@helpers/consts'
import { SubscriptionContractAccommodationVariants } from '@modules/offers/subscription-contract/details/subscription-contract-accommodation-variants'
import { Accommodation } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { SubscriptionContractPlanKind } from '@models/app-state'
import { useSubscriptionContractsGtmEvents } from '@modules/offers/subscription-contract/details/use-subscription-contract-gtm-events'
import { selectAppState } from '@store/slices/app-slice'
import { useAppSelector } from '@store/index'

interface FormInputs {
  subscription_contract_rules: boolean
  accommodation: Accommodation
  planKind: SubscriptionContractPlanKind | null
}

export const SubscriptionContractView = (): JSX.Element => {
  const { isAppReady } = useAppSelector(selectAppState)
  const resortGalleryRef = React.useRef<HTMLDivElement>(null)
  const { sendViewItemListEvent } = useSubscriptionContractsGtmEvents()

  const methods = useForm<FormInputs>({
    defaultValues: { planKind: null, accommodation: 'apartment', subscription_contract_rules: false },
    mode: 'onChange',
  })

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  React.useEffect(() => {
    if (isAppReady) sendViewItemListEvent()
  }, [isAppReady])

  return (
    <OfferDetailsLayout
      questions={FAQ_QUESTIONS}
      ref={resortGalleryRef}
      resortGalleryTitle="Gdzie wykorzystać Subskrypcję?"
      resortIds={[2, 1, 5, 8, 6, 7]}
      resortGallerySubtitle="Mamy aż 6 nadmorskich lokalizacji do wyboru"
    >
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo
          images={SLIDER_IMAGES}
          benefits={BENEFITS}
          title={
            <span>
              Subskrypcja od <span className="text-nowrap">Holiday Park & Resort</span>
            </span>
          }
          description={
            <p>
              W ramach Subskrypcji oferujemy szereg bonusów i udogodnień specjalnie dla Gości Holiday Park & Resort. Już
              na starcie otrzymasz wyjątkowe prezenty.
            </p>
          }
        >
          <SubscriptionContractCostsInfo />
          <div className="d-flex flex-sm-row flex-column align-items-sm-center">
            <OfferRuleLink keyword="subscription_contract_rules" title=" Regulamin oferty Subskrypcji" />
            <IconWithText
              text="Gdzie wykorzystasz Subskrypcję?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="text-darker-gray fw-semi-bold ms-sm-5 mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
        </OfferDetailsBaseInfo>
        <SubscriptionContractAccommodationVariants />
        <SubscriptionContractOfferSelection />
        <SubscriptionContractPersonalData />
        <SubscriptionContractPayment />
      </FormProvider>
    </OfferDetailsLayout>
  )
}

const BENEFITS = [
  'Kod na 5-dniowy pobyt resortach nadmorskich w Holiday Park & Resort odnawiany co 12 miesięcy',
  'Dedykowane promocje dostępne wyłącznie dla aktywnych Subskrybentów',
  'Możliwość rezerwacji pobytu z Pakietu w Terminach Specjalnych z wykorzystaniem mniejszej ilości kodów',
  'Dostęp do nowych ofert w pierwszej kolejności',
  'Do 65% rabatu na pobyty z Voucherem Wyjazdowym',
  'Do 25% rabatu na wyżywienie',
  'Realna wartość oszczędności na kwotę nawet kilku tysięcy złotych',
]

const SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/subscription-contract/gallery/swimming-pool-2.webp'),
    thumbnail: require('@assets/images/offers/subscription-contract/gallery/swimming-pool-2.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-contract/gallery/playground-sand.webp'),
    thumbnail: require('@assets/images/offers/subscription-contract/gallery/playground-sand.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-contract/gallery/water-fun.webp'),
    thumbnail: require('@assets/images/offers/subscription-contract/gallery/water-fun.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-contract/gallery/playground.webp'),
    thumbnail: require('@assets/images/offers/subscription-contract/gallery/playground.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-contract/gallery/winter-holiday.webp'),
    thumbnail: require('@assets/images/offers/subscription-contract/gallery/winter-holiday.webp'),
  },
  {
    original: require('@assets/images/offers/subscription-contract/gallery/swimming-pool.webp'),
    thumbnail: require('@assets/images/offers/subscription-contract/gallery/swimming-pool.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'Ile kosztuje Subskrypcja?',
    answer:
      'Koszt Subskrypcji różni się w zależności od wybranego wariantu oferty. Ceny zaczynają się już od 49,00 zł miesięcznie.',
  },
  {
    question: 'Jak odbywa się płatność za Subskrypcję?',
    answer:
      'Uzupełnij dane karty płatniczej w bezpiecznej bramce płatności Operatora Przelewy24. Przed pierwszą płatnością zostanie pobrana zwrotna płatność weryfikacyjna w kwocie 1,00 zł. Następnie, w ramach comiesięcznej płatności cyklicznej, Operator Przelewy24 będzie automatycznie pobierał środki z karty, w podanych wcześniej terminach.',
  },
  {
    question: 'Czy muszę mieć rezerwację, żeby rozpocząć Subskrypcję?',
    answer:
      'Nie. Subskrypcja dostępna jest dla wszystkich, niezależnie od tego, czy korzystałeś wcześniej z naszych usług.',
  },
  {
    question: 'Kiedy otrzymuję pierwsze prezenty?',
    answer:
      'Już od pierwszego dnia aktywnej Subskrypcji otrzymujesz pierwsze bonusy. Jednym z nich jest kod na 5-dniowy pobyt do odbycia już po 12 miesiącach.',
  },
  {
    question: 'Kiedy mogę skorzystać z otrzymanych benefitów?',
    answer: (
      <span>
        <span className="fw-semi-bold d-block">Od razu po rozpoczęciu Subskrypcji! </span>
        Możesz m.in. dokonać rezerwacji 5-dniowego pobytu z użyciem otrzymanego kodu czy zarezerwować pobyt z Pakietu w
        Terminach Specjalnych z wykorzystaniem jednego kodu pakietowego mniej niż regularnie. Już od drugiego miesiąca
        Subskrypcji korzystaj ze zniżek na wyżywienie i wielu innych korzyści!
      </span>
    ),
  },
  {
    question: 'Co, jeżeli moja karta straci ważność, ulegnie zagubieniu lub zniszczeniu? ',
    answer:
      'Niezależnie od powodu, możesz zmienić kartę w każdym momencie trwania Subskrypcji. Pamiętaj natomiast, że jesteś zobowiązany utrzymywać wskazaną kartę, tj. musi być ona ważna oraz posiadać środki wystarczające do pobierania cyklicznej płatności w ustalonej kwocie.',
  },
  {
    question: 'Jak mogę zmienić kartę? ',
    answer: (
      <span>
        To proste! :) Możesz to zrobić w swoim{' '}
        <a
          className="fw-semi-bold text-decoration-none text-secondary"
          href={CLIENT_APP_URLS.MAIN_PAGE}
          target="_blank"
        >
          Koncie Gościa
        </a>{' '}
        - w szczegółach Subskrypcji znajdziesz przycisk "Zmień" obok numeru karty. Zostaniesz przeniesiony do strony
        Operatora Przelewy24, gdzie samodzielnie uzupełnisz nowe dane.
      </span>
    ),
  },
  {
    question: 'Jaki jest termin zakończenia Subskrypcji?',
    answer: 'Subskrypcja jest bezterminowa, a benefity odnawiają się co 12 miesięcy.',
  },
  {
    question: 'Czy mogę zrezygnować z Subskrypcji?',
    answer: 'Oczywiście, możesz zrezygnować w każdym momencie.',
  },
]
