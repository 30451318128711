import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { BaseGastroCardAmountInput } from '@modules/offers/common/base-gastro-card-amount/base-gastro-card-amount-input'

export const BaseGastroCardAmountBox = (): JSX.Element => (
  <div className="gastro-card-boost__amount-box d-grid" style={{ gridTemplateRows: '1fr 1fr 1fr' }}>
    <span className="text-darker-gray text-center d-block font-xxxl lh-initial fw-semi-bold d-block">Wpłacasz</span>
    <div className="col-8 mx-auto d-flex align-items-center justify-content-center">
      <BaseGastroCardAmountInput />
    </div>

    <span className="d-block text-center font-md mt-3 mt-lg-0">
      Minimalna kwota zakupu <br />
      to {formatPriceShort(500)}.
    </span>
  </div>
)
