import * as React from 'react'
import { OfferDetailsAgreements } from '@modules/offers/common/details/personal-data/offer-details-agreements'
import { useGastroCardProduct } from '@modules/offers/gastro-card-boost/details/use-gastro-card-product'
import { GastroCardCode } from '@models/app-state'

interface Props {
  kind: GastroCardCode
}

export const GastroCardAgreements = ({ kind }: Props): JSX.Element => {
  const gastroCardProduct = useGastroCardProduct(kind)

  return <OfferDetailsAgreements rulesUrl={gastroCardProduct?.urls?.rules || ''} />
}
