import * as React from 'react'
import clsx from 'clsx'
import { useFormContext, useWatch } from 'react-hook-form'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { SubscriptionContractPlanKindColor } from '@models/app-state'
import { EndlessHolidayFormInputs } from '@modules/offers/endless-holiday/details'
import { declinateSubscriptionAmount } from '@modules/offers/endless-holiday/helpers'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'

interface Props {
  icon?: React.ReactNode
  title: React.ReactNode
  subtitle: React.ReactNode
  children: React.ReactNode
  variant?: SubscriptionContractPlanKindColor
  isDefaultExpanded?: boolean
  year: string
}

export const EndlessHolidayDetailsProductsYearSelection = ({
  year,
  icon,
  title,
  subtitle,
  children,
  variant,
  isDefaultExpanded = false,
}: Props): React.ReactNode => {
  const [expandedYears, setExpandedYears] = React.useState(isDefaultExpanded ? [year] : [])

  const { control } = useFormContext<EndlessHolidayFormInputs>()
  const [addedProducts, accommodation] = useWatch({ control, name: ['products', 'accommodation'] })

  const handleToggle = () => {
    setExpandedYears(expanded => (expanded.length ? [] : [year]))
  }

  const productsAddedForYearAmount = addedProducts.reduce((acc, curr) => {
    const isProductForYear = curr.product.stay_in_years?.includes(parseInt(year, 10))

    const isHouseAccommodation = ACCOMMODATION_TYPES.HOUSES.includes(curr.product.accommodation_type)
    const isProductForAccommodation = isHouseAccommodation ? accommodation === 'house' : accommodation === 'apartment'

    if (curr.product.kind === 'subscription' && isProductForYear && isProductForAccommodation) {
      return acc + curr.amount
    }

    return acc
  }, 0)

  return (
    <Accordion open={expandedYears} toggle={handleToggle} className="endless-holiday__accordion">
      <AccordionItem className={clsx({ [`is-${variant}`]: variant })}>
        <AccordionHeader targetId={year}>
          <div className="d-flex flex-column flex-lg-row align-items-center w-100">
            {icon && <div className="col-md-1 col-3">{icon}</div>}
            <div className="d-flex flex-column justify-content-center align-items-start">
              {title}
              {subtitle}
            </div>
            {!!productsAddedForYearAmount && (
              <div className="badge-success font-xs ms-lg-auto me-lg-5 text-nowrap mt-3 mt-lg-0">
                {declinateSubscriptionAmount(productsAddedForYearAmount)}
              </div>
            )}
          </div>
        </AccordionHeader>
        <AccordionBody accordionId={year}>
          <section className="col-12 col-lg-10">{children}</section>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  )
}
