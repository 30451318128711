import * as React from 'react'
import { useSelector } from 'react-redux'
import Carousel from 'react-multi-carousel'
import { ResortSectionRow } from '@components/resorts-gallery/resort-section-row'
import { selectAppData } from '@store/slices/app-slice'
import { Resort } from '@models/app-state'

const breakpoints = {
  extraLarge: { breakpoint: { max: 3000, min: 1770 }, items: 8 },
  large: { breakpoint: { max: 1770, min: 1200 }, items: 5 },
  semiLarge: { breakpoint: { max: 1200, min: 768 }, items: 3 },
  medium: { breakpoint: { max: 768, min: 468 }, items: 2 },
  small: { breakpoint: { max: 468, min: 0 }, items: 1 },
}

interface Props {
  title: string
  subtitle?: React.ReactNode
  resortIds?: number[]
}

export const ResortsGallery = React.forwardRef(
  ({ title, subtitle, resortIds = [2, 1, 5, 8, 6, 7, 9] }: Props, ref: React.Ref<HTMLDivElement>): React.ReactNode => {
    const { resorts } = useSelector(selectAppData)
    const resortToShow = resorts.filter(resort => resortIds.includes(resort.id))

    if (!resorts.length) return null

    return (
      <div className="px-lg-5" ref={ref}>
        <div className="resorts-gallery__resorts col-12">
          <strong className="font-xxl text-secondary text-center d-block">{title}</strong>
          <p className="text-darker-gray text-center mb-4">
            {subtitle ?? (
              <>
                Mamy aż 7 lokalizacji do wyboru <strong>nad morzem lub w górach</strong>
              </>
            )}
          </p>

          <Carousel
            responsive={breakpoints}
            arrows={false}
            autoPlay={false}
            showDots={true}
            shouldResetAutoplay={false}
            swipeable={true}
            infinite={true}
            itemClass="resorts-gallery__resorts__item"
          >
            {resortToShow.map((resort: Resort) => (
              <ResortSectionRow key={resort.id} resort={resort} />
            ))}
            <div className="p-5 bg-white flex-fill d-flex align-items-center justify-content-center flex-column resorts-gallery__resort">
              <img src={require('@assets/images/icons/success.svg')} height="50" alt="Nadchodząca inwestycja" />
              <div className="p-3 font-xl text-dark-blue fw-bold mx-2 text-center">Już wkrótce kolejna inwestycja</div>
            </div>
          </Carousel>
        </div>
      </div>
    )
  },
)
