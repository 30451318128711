import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import { useGastroCardProduct } from '@modules/offers/gastro-card-boost/details/use-gastro-card-product'
import { AvailableRuleKeyword, GastroCardCode } from '@models/app-state'

interface Props {
  kind: GastroCardCode
}

export const GastroCardPayment = ({ kind }: Props): React.ReactNode => {
  const { control, getValues } = useFormContext()

  const RuleKey = `${kind}_rules`

  const gastroCardProduct = useGastroCardProduct(kind)

  const [amountToSpent, declared_sell_price] = useWatch({
    control,
    name: ['amountToSpent', 'declared_sell_price'],
  })

  const paymentAction = async (baseFields: BaseFormFields) => {
    const payload = getValues()

    return await commonObjectPost<{ urls: { sale: string } }>(gastroCardProduct.urls.sale, {
      declared_sell_price: declared_sell_price,
      [RuleKey]: payload[RuleKey],
      ...baseFields,
    })
  }

  return (
    <OfferPaymentBar
      paymentAction={paymentAction}
      canSubmit={!!amountToSpent}
      totalPrice={declared_sell_price}
      rulesKeywords={[RuleKey as AvailableRuleKeyword]}
    />
  )
}
