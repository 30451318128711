import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayProduct } from '@modules/offers/endless-holiday/models'
import { EndlessHolidayFormInputs } from '@modules/offers/endless-holiday/details'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { EndlessHolidayDetailsProductsYearSelection } from '@modules/offers/endless-holiday/details/products-selection/product-year-accordion'
import { EndlessHolidayProductVariant } from '@modules/offers/endless-holiday/details/products-selection/product-variant/endless-holiday-product-variant'
import { EndlessHolidayProductVariantOptions } from '@modules/offers/endless-holiday/details/products-selection/product-variant/options/endless-holiday-product-variant-options'

const InitialGroupedProducts = {
  gold: { house: null, apartment: null },
  silver: { house: null, apartment: null },
  brown: { house: null, apartment: null },
}

interface Props {
  year: string
  products: EndlessHolidayProduct[]
  gastroProducts: EndlessHolidayProduct[]
  priceSurplusWithoutGastro: string
}

export const EndlessHolidayProductsVariantsYear = ({
  products,
  year,
  gastroProducts,
  priceSurplusWithoutGastro,
}: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()

  const selectedAccommodationType = useWatch({ control, name: 'accommodation' })

  const grouped = products.reduce((acc, product) => {
    const accommodationType = ACCOMMODATION_TYPES.HOUSES.includes(product.accommodation_type) ? 'house' : 'apartment'

    return {
      ...acc,
      [product.subscription_kind]: { ...acc[product.subscription_kind], [accommodationType]: product },
    }
  }, InitialGroupedProducts)

  const gold = grouped.gold[selectedAccommodationType]
  const silver = grouped.silver[selectedAccommodationType]
  const brown = grouped.brown[selectedAccommodationType]

  const variants = [
    ...(gold ? [{ subscriptionProduct: gold, variant: 'gold' }] : []),
    ...(silver ? [{ subscriptionProduct: silver, variant: 'silver' }] : []),
    ...(brown ? [{ subscriptionProduct: brown, variant: 'brown' }] : []),
  ]

  return (
    <EndlessHolidayDetailsProductsYearSelection
      year={year}
      title={<h5 className="font-500 font-xxl mx-auto mx-lg-0 ">Od roku {year}</h5>}
      subtitle={<YearSubtitle accommodationType={selectedAccommodationType} />}
    >
      {variants.map(({ subscriptionProduct, variant }) => (
        <Variant
          key={variant}
          variant={variant}
          subscriptionProduct={subscriptionProduct}
          gastroProducts={gastroProducts}
          priceSurplusWithoutGastro={priceSurplusWithoutGastro}
        />
      ))}
    </EndlessHolidayDetailsProductsYearSelection>
  )
}

const YearSubtitle = ({ accommodationType }) => (
  <span className="text-muted col-10 col-lg-12 text-center text-lg-start mx-auto mx-lg-0">
    {accommodationType === 'house'
      ? 'Każdy pakiet to 6-dniowy pobyt w 9-osobowym (max. 6 os. dorosłych) domku nad morzem lub w górach.'
      : 'Każdy pakiet to 6-dniowy pobyt w 4-osobowym (max. 3 os. dorosłe) apartamencie nad morzem lub w górach.'}
  </span>
)

const Variant = ({ variant, subscriptionProduct, gastroProducts, priceSurplusWithoutGastro }) => {
  const variantData = {
    gold: {
      title: 'Pakiet złoty',
      icon: require('@assets/images/offers/subscriptions/subscription-variants/gold.svg'),
      dates: 'od 26.08 do 07.07 ',
    },
    silver: {
      title: 'Pakiet srebrny',
      icon: require('@assets/images/offers/subscriptions/subscription-variants/silver.svg'),
      dates: 'od 01.09 do 30.06*',
      expandedDatesText: '* z wyłączeniem Terminów Specjalnych',
    },
    brown: {
      title: 'Pakiet brązowy',
      icon: require('@assets/images/offers/subscriptions/subscription-variants/bronze.svg'),
      dates: 'od 01.10 do 31.05* ',
      expandedDatesText: '* z wyłączeniem Terminów Specjalnych',
    },
  }[variant]

  const availableGastroProducts =
    subscriptionProduct.stay_in_years?.[0] === 2025
      ? gastroProducts.filter((gastroProduct: EndlessHolidayProduct) => gastroProduct.price_brutto === '2000.00')
      : gastroProducts

  return (
    <EndlessHolidayProductVariant
      priceSurplusWithoutGastro={priceSurplusWithoutGastro}
      gastroProduct={availableGastroProducts[0]}
      subscriptionProduct={subscriptionProduct}
      variant={variant}
      title={<h5 className="fw-bolder subscription-variant__header is-brown">{variantData.title}</h5>}
      icon={<img src={variantData.icon} alt={variantData.title} height="40" />}
      subtitle={
        <div className="fw-normal text-muted mt-1 font-sm">
          <span className="d-lg-inline d-none">Do wykorzystania w terminie: </span>
          <span className="text-nowrap">{variantData.dates}</span>
        </div>
      }
    >
      <EndlessHolidayProductVariantOptions
        priceSurplusWithoutGastro={priceSurplusWithoutGastro}
        subscriptionProduct={subscriptionProduct}
        gastroProducts={availableGastroProducts}
      >
        <span className="opacity-75 text-darker-gray font-xxs">{variantData.expandedDatesText}</span>
      </EndlessHolidayProductVariantOptions>
    </EndlessHolidayProductVariant>
  )
}
