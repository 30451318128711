import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { formatPriceShort } from '@helpers/price-helper'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/slices/app-slice'
import { hasActiveSubscriptionContract } from '@modules/offers/gastro-card-boost/utils'
import { BaseGastroCardAmountBox } from '@modules/offers/common/base-gastro-card-amount/base-gastro-card-amount-box'
import { useToggle } from 'rooks'
import { OfferSelectionButton } from '@modules/offers/common/details/offer-selection-button'
import Decimal from 'decimal.js'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import * as clsx from 'clsx'

interface ChildrenProps {
  amountToSpent: string
  amountValue: { subscription: string; regular: string }
}

interface Props {
  subscriptionFactor: number
  regularFactor: number
  sectionTitle: React.ReactNode
  centerCardTitle: React.ReactNode
  children: (props: ChildrenProps) => React.ReactNode
  kindClassName?: string
  subscriptionBonuses?: ({ subscriptionPercentageBonus }) => React.ReactNode
  amountBox?: React.ReactNode
}

export const BaseGastroCardAmount = ({
  subscriptionFactor,
  regularFactor,
  sectionTitle,
  centerCardTitle,
  children,
  kindClassName,
  subscriptionBonuses,
  amountBox,
}: Props): React.ReactNode => {
  const [isSelected, toggleIsSelected] = useToggle(false)
  const client = useSelector(selectClientDetails)

  const hasActiveSubscription = hasActiveSubscriptionContract(client)

  const regularPercentageBonus = Math.max(regularFactor * 100 - 100, 0)
  const subscriptionPercentageBonus = Math.max(subscriptionFactor * 100 - 100)

  const percentageDiscount = `${hasActiveSubscription ? subscriptionPercentageBonus : regularPercentageBonus}`

  const { control, setValue } = useFormContext()

  const declaredSellPrice = useWatch({ control, name: 'declared_sell_price' })

  const amountValue = {
    subscription: new Decimal(declaredSellPrice).mul(subscriptionFactor).toString(),
    regular: new Decimal(declaredSellPrice).mul(regularFactor).toString(),
  }

  const amountToSpent = hasActiveSubscription ? amountValue.subscription : amountValue.regular

  useDidUpdateEffect(() => {
    if (isSelected) {
      setValue('amountToSpent', amountToSpent)
    } else {
      setValue('amountToSpent', null)
    }
  }, [isSelected])

  return (
    <div className="container-xl">
      <OfferDetailsRow title={sectionTitle}>
        <div className="d-flex gap-1 flex-wrap flex-md-nowrap">
          {amountBox ?? <BaseGastroCardAmountBox />}
          <div className="gastro-card-boost__amount-percentage-box">
            <div className="gastro-card-boost__amount__plus">
              <i className="uil-plus" />
            </div>
            <div className="d-grid h-100" style={{ gridTemplateRows: '1fr 1fr 1fr' }}>
              <span className="text-primary d-block text-center font-xxxl lh-initial fw-semi-bold">
                {centerCardTitle}
              </span>
              <div className="font-header text-primary text-center font-xxxl align-self-center">
                <div>
                  <span style={{ fontSize: 60 }} className="fw-semi-bold">
                    {percentageDiscount}
                  </span>
                  <span className="fw-normal font-xxxl ms-1">%</span>
                </div>
                <span className="fw-normal font-xxl">bonusu</span>
              </div>
              {subscriptionBonuses?.({ subscriptionPercentageBonus })}
            </div>
            <div className={clsx('gastro-card-boost__amount__equal', { 'is-selected': isSelected }, kindClassName)}>
              <span className="gastro-card-boost__amount__equal__sign">=</span>
            </div>
          </div>
          <div
            className={clsx('gastro-card-boost__amount-selection-box', { 'is-selected': isSelected }, kindClassName)}
          >
            {children({ amountToSpent, amountValue })}
            <OfferSelectionButton
              text="Dodaj voucher"
              activeText="Voucher dodany"
              isSelected={isSelected}
              onSelectionChange={toggleIsSelected}
            />
          </div>
        </div>
        <div className="mt-4">
          <p className="mb-1 font-xl">
            Kwota do zapłaty: <strong className="text-nowrap">{formatPriceShort(declaredSellPrice)}</strong>
          </p>
          <p className="mb-1 font-xl">
            Kwota do wykorzystania na ofertę gastronomiczną z zastosowaniem promocji:{' '}
            <strong className="text-nowrap">{formatPriceShort(amountToSpent)}</strong>
          </p>
        </div>
      </OfferDetailsRow>
    </div>
  )
}
