import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { EndlessHolidayFormInputProduct } from '@modules/offers/endless-holiday/details'
import { asDecimal } from '@helpers/utils'
import { EndlessHolidaySummaryOptionRow } from '@modules/offers/endless-holiday/details/summary/option-row'
import { ACCOMMODATION_TYPES } from '@helpers/consts'

interface Props {
  subscription: EndlessHolidayFormInputProduct
  className: string
}

export const EndlessHolidaySummarySubscriptionRow = ({ subscription, className }: Props): React.ReactNode => {
  const price = asDecimal(subscription.product.price_brutto).mul(subscription.amount).toString()
  const depositPrice = asDecimal(subscription.product.deposit_amount).mul(subscription.amount).toString()

  const amount = `${subscription.amount > 1 ? `x${subscription.amount}` : ''}`

  const getSubscriptionName = () => {
    const accommodationType = ACCOMMODATION_TYPES.HOUSES.includes(subscription.product.accommodation_type)
      ? 'Domek'
      : 'Apartament'

    const variant = {
      gold: 'Pakiet złoty',
      silver: 'Pakiet srebrny',
      brown: 'Pakiet brązowy',
    }[subscription.product.subscription_kind]

    return `${accommodationType} - ${variant} ${amount}`
  }

  return (
    <li key={subscription.product.id} className={className}>
      <div className="text-darker-gray d-flex justify-content-between">
        <div className="fw-bold">{getSubscriptionName()}</div>
        <div className="fw-bold font-lg">{formatPriceShort(price)}</div>
      </div>

      <div className="text-muted d-flex justify-content-between ms-3 font-500">
        <div>Kaucja zwrotna do przyszłej rezerwacji {amount}</div>
        <div>+ {formatPriceShort(depositPrice)}</div>
      </div>

      {subscription.options.map(option => (
        <EndlessHolidaySummaryOptionRow key={option.kind} option={option} amount={subscription.amount} />
      ))}
    </li>
  )
}
