import * as React from 'react'
import { AppHeader } from '@components/header/app-header'
import { Layout } from './layout'
import { AppFooterSection } from '@components/footer/app-footer-sections'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { IconWithText } from '@components/icon-with-text'
import { FaqQuestion, FaqSection } from '@components/faq-section'
import { ResortsGallery } from '@components/resorts-gallery/resorts-gallery'

interface Props {
  children: React.ReactNode
  questions: FaqQuestion[]
  resortGalleryTitle: string
  resortGallerySubtitle?: React.ReactNode
  resortIds?: number[]
}

export const OfferDetailsLayout = React.forwardRef(
  (
    { children, questions, resortGalleryTitle, resortIds, resortGallerySubtitle }: Props,
    resortGalleryRef: React.Ref<HTMLDivElement>,
  ): React.ReactNode => (
    <Layout>
      <AppHeader />
      <div className="bg-white pb-3">
        <div className="container-xl">
          <Link to={NavigationPath.Homepage} className="text-decoration-none py-4 d-block">
            <IconWithText
              text="Pokaż wszystkie oferty"
              iconClassName="uil-angle-left font-xxxl lh-1"
              className="fw-semi-bold text-darker-gray font-lg"
            />
          </Link>
        </div>
        {children}
      </div>
      <FaqSection questions={questions} />
      <ResortsGallery
        resortIds={resortIds}
        title={resortGalleryTitle}
        subtitle={resortGallerySubtitle}
        ref={resortGalleryRef}
      />

      <AppFooterSection />
    </Layout>
  ),
)
