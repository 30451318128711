export const declinateSubscriptionAmount = (number: number): string => {
  const units = number % 10

  if (number === 1) {
    return `${number} pakiet`
  } else if (units > 1 && units < 5) {
    return `${number} pakiety`
  } else {
    return `${number} pakietów`
  }
}

export const declinationProductsAmount = (number: number): string => {
  const units = number % 10

  if (number === 1) {
    return 'produkt'
  } else if (units > 1 && units < 5) {
    return 'produkty'
  } else {
    return 'produktów'
  }
}
