import * as React from 'react'
import classNames from 'classnames'
import { Calendar } from 'react-date-range'
import { pl } from 'date-fns/locale'
import { formatDate } from '@helpers/date-formatter'
import { useDevicesSize } from '@hooks/use-devices-size'

interface Props {
  date: Date | undefined
  onChange: (range) => void
  minDate?: Date | null
  maxDate?: Date | null
  placeholder?: string
  className?: string
  inputClassName?: string
  wrapperClassName?: string
  isDisabled?: boolean
  isInvalid?: boolean
  isClearable?: boolean
  dateFormat?: string
  onShownDateChange?: (date: Date) => void
}

const DatePickerComponent: React.FC<Props> = ({
  date,
  onChange,
  className,
  inputClassName,
  isDisabled,
  wrapperClassName,
  isInvalid,
  minDate,
  maxDate,
  placeholder,
  dateFormat,
  isClearable,
  onShownDateChange,
}) => {
  const { isDesktop } = useDevicesSize()
  const pickerRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = React.useState(false)

  const onPageClick = (event: MouseEvent) => {
    if (isOpen && !pickerRef.current?.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', onPageClick)
    }

    return () => document.body.removeEventListener('click', onPageClick)
  }, [isOpen])

  const showDatePicker = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation()
    if (!isDisabled) {
      setOpen(true)
    }
  }

  const handleClearDate = (): void => {
    onChange(null)
  }

  const onDateChange = (event: Date): void => {
    onChange(event)
    if (isOpen) {
      setOpen(false)
    }
  }

  const onMonthChange = (event: Date): void => {
    if (onShownDateChange) {
      onShownDateChange(event)
      if (isOpen) {
        setOpen(false)
      }
    }
  }

  return (
    <div className={classNames('date-picker', wrapperClassName)} role="date-picker">
      <span
        className={classNames('form-control date-picker__date', inputClassName, {
          'is-invalid': isInvalid,
        })}
        onClick={showDatePicker}
      >
        {date ? (
          formatDate(date, dateFormat || 'yyyy-MM-dd')
        ) : (
          <span className="date-picker__placeholder">{placeholder || 'Wybierz datę'}</span>
        )}
      </span>
      {isClearable && date && <span className="date-picker__clear" onClick={handleClearDate} />}
      {isOpen && (
        <div className="border rounded date-picker__wrapper" ref={pickerRef}>
          <Calendar
            scroll={{ enabled: !isDesktop }}
            date={date}
            locale={pl}
            onChange={onDateChange}
            className={className || ''}
            minDate={minDate || undefined}
            maxDate={maxDate || undefined}
            onShownDateChange={onMonthChange}
          />
        </div>
      )}
    </div>
  )
}

export default DatePickerComponent
