import * as React from 'react'
import { formatDate } from '@helpers/date-formatter'
import { compareAsc, parseISO } from 'date-fns'
import { GastroCardDynamicDescriptionMonths } from '@modules/offers/gastro-card/dynamic/details/modals/offer-description/gastro-card-dynamic-description-months'
import { GastroCardDynamicDescriptionBonuses } from '@modules/offers/gastro-card/dynamic/details/modals/offer-description/gastro-card-dynamic-description-bonuses'
import { useGastroCardProduct } from '@modules/offers/gastro-card-boost/details/use-gastro-card-product'

export const GastroCardDynamicDescription = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDivElement>): React.ReactNode => {
    const gastroCardDynamic = useGastroCardProduct('gastro_card_dynamic')

    const sortedMultipliers = [...(gastroCardDynamic?.multipliers ?? [])].sort((a, b) =>
      compareAsc(parseISO(a.active_from), parseISO(b.active_from)),
    )

    const months = sortedMultipliers.reduce((prevMonths, multiplier) => {
      const month = formatDate(multiplier.active_from, 'LLLL')

      return prevMonths.some(prevMonth => prevMonth === month) ? prevMonths : [...prevMonths, month]
    }, [])

    return (
      <div className="pt-3" ref={ref}>
        <p>
          Wysokość dopłat w promocji <strong>Podwójna Radość 2025 Plus</strong> nie jest stała i będzie się zmieniać w
          czasie. <span className="text-nowrap">Im szybciej kupisz voucher</span>, tym więcej bonusu zyskasz.
        </p>
        <div className="overflow-x-auto pe-lg-3">
          <table className="table table-borderless text-darker-gray font-md">
            <tbody>
              <tr>
                <td className="fw-semi-bold">Wpłata:</td>
                <GastroCardDynamicDescriptionMonths
                  months={months}
                  gastroCardMultipliers={gastroCardDynamic?.multipliers ?? []}
                />
              </tr>
              <GastroCardDynamicDescriptionBonuses months={months} sortedGastroMultipliers={sortedMultipliers} />
            </tbody>
          </table>
        </div>
      </div>
    )
  },
)
