import * as React from 'react'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { OfferDetailsPersonalData } from '@modules/offers/common/details/personal-data/offer-details-personal-data'
import { EndlessHolidayManualBox } from '@modules/offers/endless-holiday/details/endless-holiday-manual-box'
import { EndlessHolidayAgreements } from '@modules/offers/endless-holiday/details/endless-holiday-agreements'
import { EndlessHolidaySummary } from '@modules/offers/endless-holiday/details/summary/endless-holiday-summary'

interface Props {
  summaryRef: React.RefObject<HTMLDivElement>
}

export const EndlessHolidayPersonalData = ({ summaryRef }: Props): React.ReactNode => {
  const client = useAppSelector(selectClientDetails)

  return (
    <OfferDetailsPersonalData
      agreements={<EndlessHolidayAgreements />}
      title={
        <span>
          <strong className="d-block">Zamów</strong> swój voucher {client && `${client?.first_name}!`}
        </span>
      }
    >
      <EndlessHolidayManualBox />
      <EndlessHolidaySummary ref={summaryRef} />
    </OfferDetailsPersonalData>
  )
}
