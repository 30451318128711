import * as React from 'react'
import { SubscriptionWithBenefitOffer } from '@modules/offers/subscription-with-benefit/subscription-with-benefit-offer'
import { SubscriptionContractOffer } from '@modules/offers/subscription-contract'
import { CodesConversionOffer } from '@modules/offers/codes-conversion'
import { BookingOffers } from '@modules/booking-offers'
import { SubscriptionsOffer } from '@modules/offers/subscriptions'
import { useScrollToOffer } from '@hooks/use-scroll-to-offer'
import { GastroCardOffers } from '@modules/offers/gastro-card'
import { EndlessHolidayOffer } from '@modules/offers/endless-holiday'

export const OffersSection = (): React.ReactNode => {
  const { ref } = useScrollToOffer('nowosci')

  return (
    <>
      <SubscriptionWithBenefitOffer />
      <EndlessHolidayOffer />
      <div className="nowosci" ref={ref} style={{ scrollMarginBlock: -250 }}>
        <SubscriptionsOffer />
        <GastroCardOffers />
      </div>
      <CodesConversionOffer />
      <SubscriptionContractOffer />
      <BookingOffers />
    </>
  )
}
