import * as React from 'react'
import clsx from 'clsx'
import { CircleOutlineRadioButton } from '@components/controls/circle-outline-radio-button'

interface GastroCardBoxProps {
  title: string
  onClick: () => void
  isChecked: boolean
  text: React.ReactNode
  inactiveLabel?: React.ReactNode
}

export const EndlessHolidayProductVariantOptionBox = ({
  title,
  onClick,
  isChecked,
  text,
  inactiveLabel,
}: GastroCardBoxProps): React.ReactNode => (
  <div className="position-relative col-lg-4 col-12">
    <div
      className={clsx('btn-unstyled w-100 h-100 cursor-pointer', { 'pe-none opacity-25': inactiveLabel })}
      onClick={onClick}
    >
      <div className="rounded border d-flex p-3 bg-lighter-gray h-100">
        <CircleOutlineRadioButton checked={isChecked} onChange={onClick} />
        <div className="ms-1 text-start">
          <div className="font-lg fw-bold">{title}</div>
          <div className="text-muted font-xs lh-sm">{text}</div>
        </div>
      </div>
    </div>
    {inactiveLabel}
  </div>
)
