import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { formatPrice } from '@helpers/price-helper'
import clsx from 'clsx'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputProduct, EndlessHolidayFormInputs } from '@modules/offers/endless-holiday/details'
import { useEndlessHolidayTotalPrice } from '@modules/offers/endless-holiday/details/use-total-price'
import { EndlessHolidaySummaryGastroCards } from '@modules/offers/endless-holiday/details/summary/summary-gastro-cards'
import { EndlessHolidaySummarySubscriptionRow } from '@modules/offers/endless-holiday/details/summary/subscription-row'

export const EndlessHolidaySummary = React.forwardRef((_, ref: React.RefObject<HTMLDivElement>): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const { totalPrice } = useEndlessHolidayTotalPrice(products)

  const subscriptionProducts = products.filter(product => product.product.kind === 'subscription')

  const groupedByYears = subscriptionProducts.reduce((acc, subscription) => {
    const subscriptionYear = subscription.product.stay_in_years?.[0]
    if (!subscriptionYear) return acc

    if (acc[subscriptionYear]) {
      return { ...acc, [subscriptionYear]: [...acc[subscriptionYear], subscription] }
    }
    return { ...acc, [subscriptionYear]: [subscription] }
  }, {})

  const groupedList = Object.entries(groupedByYears) as [string, EndlessHolidayFormInputProduct[]][]

  if (!products.length) return null

  const getSubscriptionGastroCards = (subscriptionProducts: EndlessHolidayFormInputProduct[]) => {
    return products.filter(
      addedProduct =>
        subscriptionProducts.some(el => el.product.id === addedProduct.related_product) &&
        addedProduct.product.kind === 'gastro_card_product_package_endless_holiday',
    )
  }

  return (
    <OfferDetailsRow
      title={
        <span className="text-secondary">
          <strong className="d-block">Podsumowanie</strong> zamówienia
        </span>
      }
    >
      <div>
        <div ref={ref} className="bg-light-gray px-lg-5 px-3 py-3">
          <p className="text-gold fw-bold font-xl">Całkowity koszt zamówienia</p>
          {groupedList.map(([year, subscriptions], index) => (
            <div key={year}>
              <p className="text-secondary fw-bold mb-1 font-lg">Od roku {year}</p>
              <ul className="list-unstyled mb-0">
                {subscriptions.map((subscription: EndlessHolidayFormInputProduct, index: number) => (
                  <EndlessHolidaySummarySubscriptionRow
                    subscription={subscription}
                    key={subscription.product.id}
                    className={clsx({ 'mb-3': index < subscriptions.length - 1 })}
                  />
                ))}
              </ul>
              <EndlessHolidaySummaryGastroCards gastroCards={getSubscriptionGastroCards(subscriptions)} year={year} />
              {index < groupedList.length - 1 && <hr className="mx-n5" />}
            </div>
          ))}
        </div>
        <div className="font-xl d-flex justify-content-between align-items-center border-top border-bottom px-lg-5 px-3 py-3">
          <p className="text-darker-gray fw-bold mb-0">Do zapłaty łącznie:</p>
          <div className="fw-bold text-danger">{formatPrice(totalPrice)}</div>
        </div>
      </div>
    </OfferDetailsRow>
  )
})
