import * as React from 'react'
import { GastroCardBox } from '@modules/offers/gastro-card/gastro-card-box'
import { NavigationPath } from '@models/routes'
import { useNavigate } from 'react-router-dom'

export const GastroCardOffers = (): React.ReactNode => {
  const navigate = useNavigate()

  const handleShowDetails = (path: NavigationPath) => () => {
    navigate(path)
  }

  return (
    <div className="container-lg">
      <div className="row offers__row mt-5 mt-lg-0 border-0 gy-5 gx-0 gx-lg-3">
        <GastroCardBox
          scrollAnchor="podwojna-radosc-2025-plus"
          alt="Podwójna Radość 2025 Plus"
          title={
            <span>
              Podwójna Radość <span className="text-nowrap">2025 Plus</span>
            </span>
          }
          image={require('@assets/images/offers/gastro-card/gastro-card-dynamic.webp')}
          onActionClick={handleShowDetails(NavigationPath.GastroCardDynamic)}
          description={
            <span>
              Skorzystaj ze specjalnej promocji na <strong>Voucher Podwójna Radość 2025 Plus</strong> do wykorzystania w
              Kawiarni, Bistro, Beach Barze i Słodkim Kąciku podczas pobytów w roku 2025{' '}
              <span className="text-nowrap">w Resortach Holiday Park & Resort.</span>
            </span>
          }
        />
        <GastroCardBox
          scrollAnchor="podwojna-radosc-2026-2027"
          alt="Podwójna Radość 2026 i 2027 Extra Szansa"
          onActionClick={handleShowDetails(NavigationPath.GastroCard)}
          title={
            <span>
              Podwójna Radość <span className="text-nowrap">2026 i 2027 Extra Szansa</span>
            </span>
          }
          image={require('@assets/images/offers/gastro-card/gastro-card-2026-2027.webp')}
          description={
            <span>
              Skorzystaj ze specjalnej promocji na{' '}
              <strong>
                Voucher Podwójna Radość 2026/2027 <span className="text-nowrap">Extra Szansa</span>
              </strong>{' '}
              do wykorzystania w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku podczas pobytów{' '}
              <span className="text-nowrap">w latach 2026 - 2027</span> w Resortach Holiday Park & Resort.
            </span>
          }
        />
      </div>
    </div>
  )
}
