import * as React from 'react'
import { useModal } from '@modals/use-modal'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { useScrollToOffer } from '@hooks/use-scroll-to-offer'

export const BookingOfferHeader = (): JSX.Element => {
  const clientDetails = useAppSelector(selectClientDetails)
  const { ref } = useScrollToOffer('last-minute')

  const [showLoginModal] = useModal('LoginModal')

  return (
    <>
      <h3 className="text-center text-secondary font-header fw-bold" id="last-minute" ref={ref}>
        Dostępne terminy last minute
      </h3>
      <p className="font-xl text-center">Skorzystaj z okazji i zarezerwuj pobyt w jednym z naszych resortów</p>
      {!clientDetails && (
        <div className="mx-auto d-flex flex-column flex-sm-row align-items-center justify-content-center flex-wrap">
          <span className="badge-success font-xxs me-2 d-block d-sm-inline">Masz Subskrypcję?</span>
          <div className="d-flex d-sm-inline-flex align-items-center mt-3 mt-sm-0">
            <button className="btn-unstyled text-dark fw-semi-bold me-1" onClick={showLoginModal}>
              Zaloguj się
            </button>
            i korzystaj z dodatkowych rabatów
          </div>
        </div>
      )}
    </>
  )
}
