import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'
import { RESERVATION_APP_URLS } from '@helpers/consts'

export const EndlessHolidayAdditionalCostsInfo = (): JSX.Element => (
  <section className="font-sm my-4">
    <ExpandableProductDescription isCollapsable>
      <p>
        Kup voucher na pobyt w roku 2025, 2026, 2027, 2028 lub 2029 i korzystaj z najniższych cen. Data ważności każdego
        vouchera pobytowego to 5 lat, licząc od wybranego roku. Przykładowo: Voucher od roku 2025 będzie ważny do końca
        roku 2029.
      </p>
      <p>
        W ramach oferty nabywasz voucher pobytowy do realizacji od wybranego roku oraz voucher gastronomiczny do
        wykorzystania we wszystkich dostępnych punktach (włącznie z Restauracją), w ciągu 3 lat od początku daty
        ważności kodu pobytowego.
      </p>
      <p>
        Przykładowo: Kupując voucher na pobyt od roku 2027, Twój voucher gastronomiczny będzie ważny do końca 2029 roku,
        do użycia na różnych rezerwacjach.
      </p>
      <section className="mb-3">
        <p>Terminy wykorzystania voucherów pobytowych różnią się w zależności od wariantu Pakietu:</p>
        <ul className="list-unstyled mb-0 mt-1">
          <li>
            <strong>Pakiet Brązowy</strong> - możesz do nas przyjechać od 01.01 do 31.05 oraz od 01.10 do 31.12*
          </li>
          <li>
            <strong>Pakiet Srebrny</strong> - możesz do nas przyjechać od 01.01 do 30.06 oraz od 01.09 do 31.12*
          </li>
          <li>
            <strong>Pakiet Złoty</strong> - możesz do nas przyjechać od 01.01 do 07.07 oraz od 26.08 do 31.12
          </li>
        </ul>
        <small>*Z wyłączeniem terminów specjalnych.</small>
      </section>
      <p>
        Rezerwacji dokonuje się samodzielnie poprzez stronę{' '}
        <a className="fw-bold text-decoration-none" href={RESERVATION_APP_URLS.MAIN_PAGE} target="_blank">
          rezerwuj.holidaypark.pl
        </a>{' '}
        lub telefonicznie z pomocą Biura Obsługi Klienta pod numerem 735 200 800. Przy zakładaniu rezerwacji należy
        opłacić jednorazową usługę przygotowania lokalu. Do każdej rezerwacji zostanie doliczona opłata eksploatacyjna i
        opłata klimatyczna, płatne podczas meldowania. Przy wyjeździe należy uiścić opłatę z tytułu zużytych mediów.
        Wysokość opłat została wskazana w Cenniku oraz w Regulaminie Rezerwacji Holiday Park & Resort.
      </p>
      <p className="mb-0">Szczegółowe zasad wykorzystania określa Regulamin oferty.</p>
    </ExpandableProductDescription>
  </section>
)
