import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { formatPriceShort } from '@helpers/price-helper'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useSubscriptionContractPlans } from '@modules/offers/subscription-contract/details/use-subscription-contract-product'

export const SubscriptionContractOffer = (): JSX.Element => {
  const navigate = useNavigate()
  const plans = useSubscriptionContractPlans()

  const [cheapestSubscriptionPlan] = Object.values(plans).sort(
    (a, b) => parseFloat(a.single_price_brutto) - parseFloat(b.single_price_brutto),
  )

  const handleShowDetails = () => {
    navigate(NavigationPath.SubscriptionContract)
  }

  return (
    <div className="container-lg">
      <OfferSection
        title={<span className="font-header">Subskrypcja</span>}
        description={<SubscriptionContractDescription lowestPrice={cheapestSubscriptionPlan.single_price_brutto} />}
        onOrderClick={handleShowDetails}
        onImageClick={handleShowDetails}
        orderText="Zobacz więcej"
        rowClassName="flex-row-reverse"
        price={{
          pre: (
            <span>
              Od{' '}
              <strong className="font-header-lg">
                {formatPriceShort(cheapestSubscriptionPlan.single_price_brutto)}
              </strong>
            </span>
          ),
          after: 'miesięcznie',
          shapePosition: 'bottom',
        }}
        offerKind="subscription-contract"
      />
    </div>
  )
}

const SubscriptionContractDescription = ({ lowestPrice }) => (
  <section>
    W Holiday Park & Resort <strong className="d-block lh-initial">subskrybując możesz więcej.</strong>
    <div className="mt-3">Od {formatPriceShort(lowestPrice)} miesięcznie.</div>
  </section>
)
